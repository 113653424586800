<template>
  <div>
    <client-info-sidebar
      :isClientSidebarActive.sync="isClientSidebarActive"
      :client="client"
      @open-sidebar-form="openSidebarForm"
    />
    <client-form-sidebar
      :isClientFormSidebarActive.sync="isClientFormSidebarActive"
      :client="client"
    />
    <div class="custom-search">
      <b-row class="mb-2">
        <b-col md="3">
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            @click="() => (isClientFormSidebarActive = true)"
            variant="outline-primary"
          >
            ADICIONAR
          </b-button>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="3">
          <b-form-group>
            <label>Pesquisar:</label>
            <b-form-input
              placeholder="Search"
              type="text"
              class="d-inline-block"
              @input="advanceSearch"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group>
            <label>Status:</label>
            <b-form-select v-model="statusSelected" :options="statusOptions" />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group label="Vencimento de" label-for="init-date">
            <flat-pickr
              :id="'init-date'"
              class="form-control"
              :config="dateConfig"
              v-model="dateInitial"
              placeholder="DD/MM/YYYY"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group label="Vencimento até" label-for="end-date">
            <flat-pickr
              :id="'end-date'"
              class="form-control"
              :config="dateConfig"
              v-model="dateEnd"
              placeholder="DD/MM/YYYY"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </div>

    <!-- table -->
    <vue-good-table
      :columns="columns"
      :rows="clients"
      :rtl="direction"
      :search-options="{
        enabled: true,
        externalQuery: searchTerm,
      }"
      :select-options="{
        enabled: false,
        selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
        selectionInfoClass: 'custom-class',
        selectionText: 'rows selected',
        clearSelectionText: 'clear',
        disableSelectInfo: true, // disable the select info panel on top
        selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
      }"
      :pagination-options="{
        enabled: true,
        perPage: pageLength,
      }"
      theme="my-theme"
      @on-row-click="onRowClick"
    >
      <template slot="table-row" slot-scope="props">
        <span v-if="props.column.field === 'name'" class="text-nowrap">
          <b-avatar :src="props.row.avatar" class="mx-1" />
          <span>{{ props.row.name }}</span>
        </span>
        <span
          v-else-if="
            props.column.field === 'birthDate' ||
              props.column.field === 'dueDate'
          "
          class="text-nowrap"
        >
          {{
            props.formattedRow[props.column.field] | dateFormat("DD/MM/YYYY")
          }}
        </span>
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>

      <!-- pagination -->
      <template slot="pagination-bottom" slot-scope="props">
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap">
              Exibindo 1 até
            </span>
            <b-form-select
              v-model="pageLength"
              :options="['3', '5', '10']"
              class="mx-1"
              @input="
                (value) => props.perPageChanged({ currentPerPage: value })
              "
            />
            <span class="text-nowrap"> de {{ props.total }} registros </span>
          </div>
          <div>
            <b-pagination
              :value="1"
              :total-rows="props.total"
              :per-page="pageLength"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0"
              @input="(value) => props.pageChanged({ currentPage: value })"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>
  </div>
</template>

<script>
import {
  BAvatar,
  BButton,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BRow,
  BCol,
} from "bootstrap-vue";

import ClientInfoSidebar from "./client-info-sidebar/ClientInfoSidebar.vue";
import ClientFormSidebar from "./client-form-sidebar/ClientFormSidebar.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

import { VueGoodTable } from "vue-good-table";
import Ripple from "vue-ripple-directive";
import flatPickr from "vue-flatpickr-component";
import { Portuguese } from "flatpickr/dist/l10n/pt.js";

import store from "@/store";
import {
  isAfter,
  formatISO,
  startOfMonth,
  endOfMonth,
  parseISO,
  isWithinInterval,
} from "date-fns";

export default {
  components: {
    BAvatar,
    BButton,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BRow,
    BCol,
    VueGoodTable,
    ClientInfoSidebar,
    ClientFormSidebar,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    flatPickr,
  },

  directives: {
    Ripple,
  },

  data() {
    return {
      pageLength: 5,
      dir: false,
      columns: [
        {
          label: "Nome",
          field: "name",
        },
        {
          label: "E-mail",
          field: "email",
        },
        {
          label: "Telefone",
          field: "telephone",
        },
        {
          label: "Aniversário",
          field: "birthDate",
        },
        {
          label: "Vencimento",
          field: "dueDate",
        },
        {
          label: "Status",
          field: "status",
        },
      ],
      rows: [],
      isClientSidebarActive: false,
      isClientFormSidebarActive: false,
      client: {},
      dateConfig: {
        altInput: true,
        altFormat: "d \\de M\\. \\de Y",
        dateFormat: "Y-m-d",
        locale: Portuguese,
        allowInput: false,
      },
      dateInitial: formatISO(startOfMonth(new Date()), {
        representation: "date",
      }),
      dateEnd: formatISO(endOfMonth(new Date()), {
        representation: "date",
      }),
      statusSelected: null,
      statusOptions: [
        { value: null, text: "Selecione uma opção" },
        { value: "ativo", text: "ATIVOS" },
        { value: "expirado", text: "EXPIRADOS", disabled: false },
      ],

      searchTerm: "",
    };
  },
  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true;
        return this.dir;
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false;
      return this.dir;
    },

    clients() {
      return this.rows.filter((filter) => {
        let isDueDate =
          !filter.dueDate ||
          (isAfter(parseISO(this.dateEnd), parseISO(this.dateInitial)) &&
            isWithinInterval(parseISO(filter.dueDate), {
              start: parseISO(this.dateInitial),
              end: parseISO(this.dateEnd),
            }));

        let checkStatus =
          !this.statusSelected ||
          (this.statusSelected && filter.status === this.statusSelected);

        return isDueDate && checkStatus;
      });
    },
  },

  watch: {
    "$store.state.client.list"(list) {
      this.rows = list;
    },

    isClientSidebarActive(active) {
      if (!active) setTimeout(() => (this.client = {}), 200);
    },
  },

  async created() {
    await store.dispatch("client/fetch");
  },

  methods: {
    advanceSearch(val) {
      this.searchTerm = val;
    },

    async openSidebarForm() {
      this.isClientFormSidebarActive = true;
    },

    async onRowClick(params) {
      if (params.row.id != undefined) {
        const client = this.rows.find((c) => c.id == params.row.id);
        this.client = client;
        this.isClientSidebarActive = true;
      }
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
