<template>
  <div>
    <b-sidebar
      id="sidebar-footer"
      sidebar-class="sidebar-lg"
      aria-label="Sidebar with custom footer"
      bg-variant="white"
      :visible="isClientSidebarActive"
      @change="(val) => $emit('update:is-client-sidebar-active', val)"
      shadow
      backdrop
      no-header
      right
    >
      <template #default="{ hide }">
        <!-- Header -->
        <div
          class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
        >
          <h5 class="mb-0">CLIENTE</h5>
          <div>
            <feather-icon
              class="ml-1 cursor-pointer"
              icon="XIcon"
              size="16"
              @click="hide"
            />
          </div>
        </div>
        <div class="px-1 mt-2" v-if="Object.keys(client).length > 0">
          <b-row>
            <b-col md="9 mx-auto">
              <b-media class="text-center">
                <div class="mb-1">
                  <b-avatar
                    :src="require('@/assets/images/avatars/12-small.png')"
                    size="64"
                    v-b-tooltip.hover.v-primary
                    v-b-tooltip.hover.right="'Abrir histórico'"
                  />
                </div>
                <h6>{{ client.name }}</h6>
                <p style="margin-top:-5px" class="font-italic">
                  <small>{{ client.email }}</small>
                </p>
              </b-media>
            </b-col>
          </b-row>
          <b-row class="mt-1" v-if="client.telephone">
            <b-col>
              <h5 class="d-inline">
                Telefone:
              </h5>
              {{ client.telephone | phoneNumber }}
            </b-col>
          </b-row>
          <b-row class="mt-1" v-if="client.birthDate">
            <b-col>
              <h5 class="d-inline">
                Aniversário:
              </h5>
              {{ client.birthDate | moment("DD [de] MMM [de] YYYY") }}
            </b-col>
          </b-row>
          <b-row class="mt-1" v-if="client.dueDate">
            <b-col>
              <h5 class="d-inline">
                Vencimento:
              </h5>
              {{ client.dueDate | moment("DD/MM/YYYY") }}
            </b-col>
          </b-row>
          <b-row class="mt-1">
            <b-col>
              <h5 class="d-inline">
                Status:
              </h5>
              {{ client.status | ucFirst }}
            </b-col>
          </b-row>
          <b-row class="mt-1" v-if="client.description">
            <b-col>
              <h5 class="d-inline">
                Descrição:
              </h5>
              {{ client.description | ucFirst }}
            </b-col>
          </b-row>
        </div>
      </template>
      <template #footer>
        <sidebar-footer
          :client="client"
          @hide="() => $emit('update:is-client-sidebar-active', false)"
          @open-sidebar-form="() => $emit('open-sidebar-form', client)"
          @renew="() => renewClient()"
        />
      </template>
    </b-sidebar>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BSidebar,
  BAvatar,
  BMedia,
  VBTooltip,
} from "bootstrap-vue";

import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import store from "@/store";

import SidebarFooter from "./SidebarFooter.vue";
import { format, parseISO, addMonths, isFuture } from "date-fns";

export default {
  components: {
    BRow,
    BCol,
    BSidebar,
    BAvatar,
    BMedia,
    SidebarFooter,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },

  directives: {
    "b-tooltip": VBTooltip,
  },

  props: {
    isClientSidebarActive: {
      type: Boolean,
      required: true,
    },
    client: {
      type: Object,
      required: true,
    },
  },

  methods: {
    async renewClient() {
      try {
        if (!Object.keys(this.client).length) {
          return;
        }

        let dueDate = addMonths(parseISO(this.client.dueDate), 1);
        if (!isFuture(dueDate)) dueDate = addMonths(new Date(), 1);

        const action = await this.$bvModal.msgBoxConfirm(
          `Tem certeza que deseja renovar a mensalidade de ${
            this.client.name
          } para a data ${format(dueDate, "dd/MM/yyyy")}?`,
          {
            title: "Tem certeza desta ação?",
            size: "sm",
            okVariant: "primary",
            okTitle: "Sim",
            cancelTitle: "Não",
            cancelVariant: "outline-secondary",
            hideHeaderClose: true,
            centered: true,
          }
        );

        if (action) {
          await store.dispatch("client/renew", this.client);
          this.$toast({
            component: ToastificationContent,
            props: {
              title: `Mensalidade de ${this.client.name} renovada com sucesso`,
              icon: "UserIcon",
              variant: "success",
            },
          });
        }
      } catch (err) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title:
              err.response != undefined
                ? err.response.data.message
                : `Erro ao renovar o cliente`,
            icon: "UserIcon",
            variant: "danger",
          },
        });
      }
    },
  },
};
</script>
