<template>
  <div>
    <b-sidebar
      id="sidebar-footer"
      sidebar-class="sidebar-lg"
      aria-label="Sidebar with custom footer"
      bg-variant="white"
      :visible="isClientFormSidebarActive"
      @change="(val) => $emit('update:is-client-form-sidebar-active', val)"
      shadow
      backdrop
      no-header
      right
    >
      <template #default="{ hide }">
        <!-- Header -->
        <div
          class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
        >
          <h5 class="mb-0" v-if="client.id !== undefined">EDITAR CLIENTE</h5>
          <h5 v-else class="mb-0">ADICIONAR CLIENTE</h5>
          <div>
            <feather-icon
              class="ml-1 cursor-pointer"
              icon="XIcon"
              size="16"
              @click="hide"
            />
          </div>
        </div>
        <div class="px-1 mt-2">
          <b-form>
            <b-row class="mt-1">
              <b-col sm="12">
                <b-form-group label="Nome" label-for="name">
                  <b-form-input
                    :id="'name'"
                    v-model="clientLocal.name"
                    type="text"
                    placeholder="Nome"
                    trim
                  />
                </b-form-group>
              </b-col>
              <b-col sm="12">
                <b-form-group label="Telefone" label-for="telephone">
                  <b-form-input
                    :id="'telephone'"
                    v-model="clientLocal.telephone"
                    type="text"
                    maxlength="15"
                    placeholder="Telefone"
                    v-mask="['(##) ####-####', '(##) #####-####']"
                    trim
                  />
                </b-form-group>
              </b-col>
              <b-col sm="12">
                <b-form-group label="E-mail" label-for="email">
                  <b-form-input
                    :id="'email'"
                    v-model="clientLocal.email"
                    type="text"
                    placeholder="E-mail"
                    trim
                  />
                </b-form-group>
              </b-col>
              <b-col sm="12">
                <b-form-group label="Data de aniversário" label-for="birthDate">
                  <flat-pickr
                    :id="'birthDate'"
                    class="form-control"
                    :config="dateConfig"
                    v-model="clientLocal.birthDate"
                  />
                </b-form-group>
              </b-col>
              <b-col sm="12">
                <b-form-group label="Status">
                  <v-select
                    v-model="clientLocal.status"
                    :options="statusOptions"
                    :reduce="(val) => val.value"
                    :clearable="false"
                  />
                </b-form-group>
              </b-col>
              <b-col sm="12">
                <b-form-group label="Data de vencimento" label-for="dueDate">
                  <flat-pickr
                    :id="'dueDate'"
                    class="form-control"
                    :config="dateConfig"
                    v-model="clientLocal.dueDate"
                  />
                </b-form-group>
              </b-col>
              <b-col sm="12">
                <b-form-group label="Descrição" label-for="description">
                  <b-form-textarea
                    :id="'description'"
                    v-model="clientLocal.description"
                    type="text"
                    trim
                  />
                </b-form-group>
              </b-col>
            </b-row>
          </b-form>
        </div>
      </template>
      <template #footer>
        <sidebar-footer
          :client="client"
          @hide="() => $emit('update:is-client-form-sidebar-active', false)"
          @form-submit="() => submitForm()"
        />
      </template>
    </b-sidebar>
  </div>
</template>

<script>
import {
  BCol,
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BRow,
  VBTooltip,
} from "bootstrap-vue";

import SidebarFooter from "./SidebarFooter.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import { Portuguese } from "flatpickr/dist/l10n/pt.js";

import store from "@/store";

export default {
  components: {
    BRow,
    BCol,
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    SidebarFooter,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    vSelect,
    flatPickr,
  },

  directives: {
    "b-tooltip": VBTooltip,
  },

  props: {
    isClientFormSidebarActive: {
      type: Boolean,
      required: true,
    },
    client: {
      type: Object,
      required: true,
    },
  },

  data() {
    const client = {
      name: "",
      email: "",
      telephone: "",
      birthDate: "",
      status: "",
      dueDate: "",
      description: "",
    };

    return {
      clientLocal: Object.assign(client, this.client),
      statusOptions: [
        { label: "Ativo", value: "ativo" },
        { label: "Expirado", value: "expirado" },
      ],
      dateConfig: {
        altInput: true,
        altFormat: "d \\de M\\. \\de Y",
        dateFormat: "Y-m-d",
        locale: Portuguese,
        allowInput: false,
      },
    };
  },

  watch: {
    isClientFormSidebarActive() {
      this.clientLocal = Object.assign({}, this.client);
    },
  },

  methods: {
    async submitForm() {
      try {
        const data = Object.assign({}, this.clientLocal);
        data.telephone = this.clientLocal.telephone.replace(/[^\d]/g, "");
        let message = `Cliente adicionado com sucesso`;

        if (Object.keys(this.client).length) {
          await store.dispatch("client/update", data);
          message = `Cliente editado com sucesso`;
        } else {
          await store.dispatch("client/add", data);
          await store.dispatch("client/fetch");
        }

        this.$toast({
          component: ToastificationContent,
          props: {
            title: message,
            icon: "UserIcon",
            variant: "success",
          },
        });
        this.$emit("update:is-client-form-sidebar-active", false);
      } catch (err) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title:
              err.response != undefined
                ? err.response.data.message
                : `Erro ao editar o cliente`,
            icon: "UserIcon",
            variant: "danger",
          },
        });
      }
    },
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
